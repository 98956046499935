<template>
  <v-dialog v-model="infotypeNewDialog" persistent max-width="400">
    <v-card>
      <v-card-title>new speciality => {{ _decode(myId) }}</v-card-title>
      <v-card-text>
        <v-text-field
          label="name"
          dense
          v-model="view.name"
          @input="$v.view.name.$touch()"
        ></v-text-field>
        <v-text-field
          dense
          label="description"
          v-model="view.description"
          @input="$v.view.description.$touch()"
          :counter="45"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import Utils from "../../../../mixins/utils.js";
export default {
  mixins: [Utils, validationMixin],
  props: {
    infotypeNewDialog: Boolean,
    myId: { type: String },
  },
  data() {
    return {
      loading: false,
      responseMessage: null,
      view: {
        name: null,
        description: null,
      },
    };
  },
  validations: {
    view: {
      name: {
        required,
        minLength: minLength(2),
      },
      description: {
        required,
        maxLength: maxLength(45),
      },
    },
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let laboratory_categoryid = parseInt(this._decode(this.myId));
      let name = this.view.name;
      let description = this.view.description;
      let data = { laboratory_categoryid, name, description };
      let payload = { state: false, data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
